.player-component {
  & > * {
    outline: 0;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .player-controlls {

    .line-reproduction {
      display: flex;
      margin-bottom: 10px;

      input {
        margin: auto;
      }

      span {
        font-size: 14px;
        margin-left: 5px;
      }

    }

    .controlls {

      div {
        display: flex;
        justify-items: center;
      }

      .info-video {
        font-size: 16px;
        margin-top: -1px;

        .info-first {
          font-weight: bold;
          opacity: 1;
          margin-right: 5px;
        }

        .info-secund {
          font-weight: lighter;
          opacity: .5;
        }
      }

    }
  }

}

.item-component {
  bottom: 20px;
  right: -20px;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 300px;

  .box-connector {
    height: 20px;
    width: 100%;
  }
}

.bold {
  font-weight: bold;
}

.item-component-list-rep {
  max-width: 400px;
  overflow: hidden;
}

.content-list-reprodution {
  background: #333;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;

  .title {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    margin: 0;
  }

  .list-list-reproduction {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;

    .item-list-reproduction {
      background: #222;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      padding: 10px;
      cursor: pointer;
      transition: all .2s ease-out;
      align-items: center;

      &:hover {
        opacity: 0.8;
      }

    }

    .selected {
      background: #333;
    }

  }
}

.content-next {
  background: #333;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

  .title {
    font-size: 18px;
    font-weight: bold;
    padding: 10px;
    margin: 0;
  }

  .item {
    background: #222;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 10px;
    cursor: pointer;
    transition: all .2s ease-out;

    &:hover {
      background: #333;
    }

  }

}

@keyframes toUpOpacity {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  30% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(0);
  }

}

.opacity-100 {
  opacity: 1;
}

.opacity-0 {
  opacity: 0;
}

.scale-1 {
  transform: scale(1.2);
}

.scale-0 {
  transform: scale(1);
}

.z-index-0 {
  z-index: 0 !important;
}

.scroll-clean-player::-webkit-scrollbar-track {
  background-color: #222;
}

.scroll-clean-player::-webkit-scrollbar {
  width: 8px;
}

.scroll-clean-player::-webkit-scrollbar-thumb {
  background: #333;
}

.ra-input-mde {
    textarea.mde-text {
        box-sizing: border-box;
    }

    textarea:focus {
        outline: none;
    }

    div.mde-tabs {
        button:focus {
            outline: none;
        }
    }
}
